angular.module('eOpti.directives').directive('empty', [function() {
    return {
        restrict: 'A',
        link: function(scope, elem) {
            scope.$watch(
                function () {
                    return elem.children().length
                }, function (nVal) {
                    if (nVal === 0 ||  (nVal === 1 && elem.children('.clearfix').length === 1)) {
                        elem.hide()
                    } else {
                        elem.show()
                    }
                }
            )
        }
    }
}])