angular.module('eOpti.directives.inputs.numbers').directive('number', [
    '$document',
    'ngDialog',
    function ($document, ngDialog) {
        return {
            restrict: 'E',
            template: '<span ng-click="showEditDialog()" ng-transclude></span>',
            replace: true,
            transclude: true,
            scope: {
                field: '@',
                model: '=',
                pattern: '@',
                float: '@',
                minus: '@?',
                ngDisabled: '=?',
                callback: '&?',
                block: '=?',
                allow: '@?',
                heading: '@?',
                none: '=?',
                noneName: '@?',
                min: '@',
                max: '@'
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showEditDialog = function () {
                        var container = {
                            value: $scope.model[$scope.field]
                        }, options = {
                            closeByDocument: false,
                            showClose: false,
                            className: 'ngdialog-theme-plain',
                            template: 'app/views/directives/inputs/numbers/number.html',
                            disableAnimation: true,
                            data: {
                                model: container,
                                pattern: $scope.pattern,
                                float: $scope.float,
                                minus: $scope.minus,
                                allow: $scope.allow,
                                heading: $scope.heading,
                                min: $scope.min,
                                max: $scope.max
                            },
                            controller: [
                                '$scope',
                                '$timeout',
                                '$document',
                                function ($innerScope, $timeout, $document) {
                                    $innerScope.firstAdd = true;

                                    if(typeof $scope.noneName === 'undefined') {
                                        $scope.noneName = 'brak';
                                    }

                                    if(typeof $scope.none !== 'undefined' && parseInt($scope.none) === parseInt($innerScope.ngDialogData.model.value)) {
                                        $innerScope.ngDialogData.model.value = $scope.noneName;
                                    }

                                    var setAsDirty = function() {
                                        if ($innerScope.ngDialogData.model.value === $scope.noneName) {
                                            $innerScope.ngDialogData.model.value = '0';
                                        }
                                    };

                                    $scope.$watch('block', function (nVal) {
                                        $innerScope.block = nVal;
                                    });

                                    $innerScope.checkValidity = () => {
                                        let val = parseInt($innerScope.ngDialogData.model.value)

                                        if ($scope.min) {
                                            $innerScope.editor.inputHelper.$setValidity('min', val >= $scope.min)
                                        }
                                        if ($scope.max) {
                                            $innerScope.editor.inputHelper.$setValidity('max', val <= $scope.max)
                                        }
                                    }

                                    if($scope.minus) {
                                        $innerScope.minus = $innerScope.ngDialogData.model.value < 0;

                                        $innerScope.addMinus = function () {
                                            setAsDirty();

                                            $innerScope.minus = !$innerScope.minus;

                                            $innerScope.ngDialogData.model.value *= -1;

                                            $innerScope.firstAdd = false;

                                            $innerScope.checkValidity()
                                        };
                                    }

                                    $innerScope.add = function (num) {
                                        setAsDirty();

                                        if($scope.allow) {
                                            $innerScope.firstAdd = false;
                                            $innerScope.ngDialogData.model.value += num;
                                        } else {
                                            if (typeof $innerScope.ngDialogData.model.value == 'undefined' ||
                                                $innerScope.ngDialogData.model.value == '0' ||
                                                $innerScope.firstAdd
                                            ) {
                                                $innerScope.ngDialogData.model.value = num;
                                                $innerScope.firstAdd = false;
                                            } else {
                                                $innerScope.ngDialogData.model.value += num;
                                            }

                                            if ($innerScope.ngDialogData.model.value == '.') {
                                                $innerScope.ngDialogData.model.value = '0.';
                                            }
                                        }

                                        if($innerScope.minus && $innerScope.ngDialogData.model.value > 0) {
                                            $innerScope.ngDialogData.model.value *= -1;
                                        }

                                        $innerScope.checkValidity()
                                    };

                                    $innerScope.clear = function () {
                                        setAsDirty();

                                        if($scope.allow) {
                                            $innerScope.ngDialogData.model.value = '';
                                        } else {
                                            $innerScope.ngDialogData.model.value = '0';
                                            $innerScope.firstAdd = false;
                                            $innerScope.minus = false;
                                        }

                                        $innerScope.checkValidity()
                                    };

                                    $innerScope.backspace = function () {
                                        setAsDirty();

                                        if ($innerScope.firstAdd) {
                                            $innerScope.clear();
                                            return;
                                        }

                                        var str = $innerScope.ngDialogData.model.value.toString();
                                        $innerScope.ngDialogData.model.value = str.substring(0, str.length - 1);

                                        if (typeof $innerScope.ngDialogData.model.value == 'undefined' ||
                                            $innerScope.ngDialogData.model.value == '' ||
                                            $innerScope.ngDialogData.model.value == '-') {
                                            $innerScope.ngDialogData.model.value = '0';
                                        }

                                        if($innerScope.ngDialogData.model.value == 0) {
                                            $innerScope.minus = false;
                                        }

                                        $innerScope.checkValidity()
                                    };

                                    $innerScope.hasDot = function () {
                                        if(typeof $innerScope.ngDialogData.model.value === 'undefined') {
                                            $innerScope.ngDialogData.model.value = '';
                                        }

                                        return $innerScope.ngDialogData.model.value.toString().includes('.');
                                    };

                                    let keydown = e => {
                                        let key = e.which;

                                        if(key >= 96 && key <= 106) {
                                            key -= 48;
                                        }

                                        switch(key) {
                                            case 48: // 0
                                            case 49: // 1
                                            case 50: // 2
                                            case 51: // 3
                                            case 52: // 4
                                            case 53: // 5
                                            case 54: // 6
                                            case 55: // 7
                                            case 56: // 8
                                            case 57: // 9
                                                $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                break;
                                            case 109:
                                            case 189:
                                                $innerScope.addMinus();
                                                break;
                                            case 8:   // backspace
                                                $innerScope.$apply($innerScope.backspace());
                                                e.preventDefault();
                                                break;
                                            case 46: // delete
                                                $innerScope.$apply($innerScope.clear());
                                                e.preventDefault();
                                                break;
                                            case 13:  // enter
                                                //$timeout(function () {
                                                //    $innerScope.closeThisDialog(1);
                                                //});
                                                $innerScope.checkValidity()
                                                if (!$innerScope.editor.$invalid) {
                                                    $innerScope.$apply($innerScope.closeThisDialog(1));
                                                }
                                                e.preventDefault();
                                                break;
                                            case 188:  // comma
                                            case 190:  // dot
                                            case 108:  // dot (numpad)
                                            case 110:  // dot (windows)
                                                if (!$innerScope.editor.$invalid && !$innerScope.hasDot() //&&
                                                //$innerScope.ngDialogData.float
                                                ) {
                                                    $innerScope.$apply($innerScope.add('.'));
                                                }
                                                break;
                                        }
                                    }

                                    $document.on('keydown', keydown);
                                    $innerScope.$on('$destroy', function () {
                                        $document.off('keydown', keydown)
                                    })
                                }
                            ]
                        };

                        if ($scope.ngDisabled !== 'undefined' && !$scope.ngDisabled) {
                            var dialog = ngDialog.open(options);
                            dialog.closePromise.then(function (data) {
                                if (data.value == 1) {
                                    if(typeof $scope.none !== 'undefined' && container.value === $scope.noneName) {
                                        $scope.model[$scope.field] = parseInt($scope.none);
                                    } else {
                                        $scope.model[$scope.field] = container.value || 0;
                                    }

                                    $scope.callback({ model: $scope.model });
                                }
                            });
                        }
                    }
                }
            ]
        }
    }
]);
