angular.module('eOpti.directives.optometrist.extra').directive('eyeTracking', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'app/views/directives/optometrist/extra/eye-tracking.html',
            replace: true,
            transclude: true,
            scope: {
                side: '@',

                model: '=',
                field: '@'
            },
            controller: [
                '$scope', '$enum',
                function ($scope, $enum) {
                    $scope.lists = [];
                    $scope.lists_circle = [];

                    $scope.active_zone = -1;
                    $scope.offset = {
                        x: 0,
                        y: 0
                    };

                    $enum.get('InterviewEyeMovementsTracking').then(function(res) {
                        $scope.lists = res;
                    });

                    $enum.get('InterviewEyeMovementsTrackingCircle').then(function(res) {
                        $scope.lists_circle = res;
                    });

                    $scope.openZone = function($event, zone) {
                        $scope.active_zone = zone;

                        $scope.offset.x = $event.offsetX;
                        $scope.offset.y = $event.offsetY;
                    };

                    $scope.setZone = function(option) {
                        $scope.model[$scope.field][$scope.active_zone] = option;

                        $scope.active_zone = -1;
                    };
                }
            ]
        }
    }
]);
