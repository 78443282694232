angular.module('eOpti.directives.inputs').directive('checkbox', ['$filter', function($filter) {
    return {
        restrict: 'E',
        require: 'ngModel',
        templateUrl: 'app/views/directives/inputs/checkbox.html',
        transclude: true,
        replace: true,
        scope: {
            ngModel: '=',
            name: '@?',
            ngDisabled: '=?',
            ngChange: '&?',
            checkboxClass: '=?'
        },
        link: function(scope, elem, attr, ctrl, transclude) {
            scope.translate = transclude().context.innerText;

            scope.lang = attr.lang ? (attr.lang === 'true') : true;

            if(scope.lang) {
                scope.translate = $filter('translate')(scope.translate);
            }

            if (typeof scope.checkboxClass === 'undefined') {
                scope.checkboxClass = 'checkbox-primary'
            }

            scope.icon = attr.icon ? attr.icon : 'fa fa-check';

            if(typeof scope.ngModel === 'undefined') {
                scope.ngModel = Object.keys(attr).indexOf('checked') !== -1 ? true : false;
            }
        }
    };
}]);
