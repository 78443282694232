angular.module('eOpti.directives.inputs').directive('radio', function() {
    return {
        restrict: 'E',
        replace: true,
        require: 'ngModel',
        templateUrl: 'app/views/directives/inputs/radio.html',
        transclude: true,
        scope: {
            ngModel: '=',
            name: '@?',
            ngDisabled: '=?',
            star: '=?',
            bold: '=?',
            value: '='
        },
        compile: function(elem, attr) {
            var radio = elem.children().find('input[type="radio"]').first(),
                attrKeys = Object.keys(attr);

            return {
                pre: function(scope, elem, attr, ctrl, transclude) {
                    scope.translate = transclude().context.innerText;

                    var checkedExists = attrKeys.indexOf('checked') !== -1;

                    if(typeof scope.ngModel === 'undefined' && checkedExists) {
                        scope.ngModel = attr.value;
                    }

                    if(typeof scope.star === 'undefined') {
                        scope.star = false;
                    }

                    if(typeof scope.bold === 'undefined') {
                        scope.bold = false;
                    }
                }
            }
        }
    };
});
