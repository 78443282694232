angular.module('eOpti.directives.table').directive('tableEmpty', ['$filter', function($filter) {
    return {
        restrict: 'A',
        link: function(scope, elem) { // element na tbody
            var textEmpty = $filter('translate')('default.table.EMPTY'),
                delete_empty_class = function() {
                    angular.forEach(elem.find('tr'), function(tr) {
                        if(angular.element(tr).hasClass('empty-row')) {
                            tr.remove();
                        }
                    });
                },
                add_empty_row = function() {
                    angular.element(elem.append('<tr class="empty-row"><td colspan="100">' +
                        '<i class="fa fa-exclamation-circle"></i> ' + textEmpty + '</td></tr>'));
                };

            scope.$watch(
                function () {
                    return [elem.find('tr').length, elem.prev().find('tr').find('th').length];
                }, function (nVal) {
                    if (parseInt(nVal) > 1) {
                        delete_empty_class();
                    } else if (!parseInt(nVal)) {
                        add_empty_row();
                    } else if(parseInt(nVal) === 1 && angular.element(elem.find('tr')[0]).hasClass('empty-row')) {
                        delete_empty_class();

                        add_empty_row();
                    }
                }, true
            )
        }
    }
}]);
