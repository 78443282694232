import {BudgetHelper} from '@/js/app/vue/helpers/BudgetHelper'
import {EventBus} from '@/js/app/_bridge/EventBus'

angular.module('eOpti.directives').directive('schedule', ['$http', '$timeout', '$localStorage', '$filter', 'dialogs', 'uac',
    function ($http, $timeout, $localStorage, $filter, dialogs, uac) {
        return {
            restrict: 'E',
            templateUrl: 'app/views/directives/schedule/schedule.html',
            $scope: {
                view: '=',
            },
            controller: ['$scope', '$rootScope', '$state', '$timeout', function($scope, $rootScope, $state, $timeout) {
                $scope.uac = uac
                let department_disabled = [86, 91, 149, 392] // to samo w OptiPatientsSchedule.vue:91, OptiScheduleNavigation.vue

                $scope.department = {
                    id: $state.params.department_id === '0' ?
                        department_disabled.includes($scope.uac.user.activeDepartmentId) ? 60 : $scope.uac.user.activeDepartmentId
                        : department_disabled.includes(parseInt($state.params.department_id)) ? 60 : parseInt($state.params.department_id)
                }

                $scope.loading = 'loading'

                $scope.hours = {
                    start: 6,
                    end: 23
                }

                $scope.working_hours = {
                    start: {
                        hour: '00',
                        minute: '00'
                    },
                    end: {
                        hour: '00',
                        minute: '00'
                    }
                }

                $scope.view = {
                    type: $state.params.view,
                    budget: !!parseInt($state.params.budget),
                    chart: {
                        users: true,
                        department: true
                    },
                    start: moment($state.params.date).set({hour: 0, minute: 0, second: 0}),
                    end: moment($state.params.date).set({hour: 23, minute: 59, second: 59}),
                    label: {
                        main: moment().format('DD-MM-YYYY'),
                        sub: moment().format('dddd')
                    }
                }

                $scope.chart_department = {
                    year: parseInt(moment().format('YYYY')),
                    quarter: BudgetHelper.getQuarterByWeek(moment())
                }

                $scope.ratio = {
                    day: {}
                }

                $scope.events = []
                $scope.budgets = {
                    users: {},
                    departments: {}
                }
                $scope.holidays = {}
                $scope.activity = {}

                $scope.days = []

                $scope.quarter_budget = {
                    users: {},
                    departments: {},
                    grouped: {}
                }

                $scope.grouped = {
                    department_ids: []
                }

                $scope.specialEvents = BudgetHelper.getSpecialEvents($scope.view.start)

                let fillDays = start => {
                    let days = [],
                        date = start.clone()

                    for(let i = 0; i < 7; i++) {
                        let day = date.format('YYYY-MM-DD')
                        days.push({
                            date: date,
                            holiday: $scope.holidays[day] || false,
                            label: {
                                short: date.format('ddd'),
                                long: date.format('ddd, Do MMM'),
                                day: day
                            },
                            special: $scope.specialEvents[day] || false
                        })
                        date.add(1, 'day')
                    }

                    $scope.days = days
                }

                if('card_quarter' in $localStorage) {
                    $scope.card_quarter = $localStorage.card_quarter
                } else {
                    $scope.card_quarter = {
                        users: true,
                        departments: false,
                        grouped: false
                    }

                    $localStorage.card_quarter = $scope.card_quarter
                }

                $scope.loading_budget = {
                    departments: 'loading',
                    grouped: 'hide'
                }

                $scope.$watch('card_quarter.departments', (nVal, oVal) => {
                    if(nVal !== oVal && nVal) {
                        loadBudgetQuarterDepartments()
                    }
                })

                let loadBudget = () => {
                    $scope.budgets.users = {}
                    $scope.quarter_budget.users = {}
                    $scope.quarter_budget.departments = {}
                    $scope.quarter_budget.grouped = {}

                    loadBudgetQuarterUsers()

                    if($scope.view.type === 'quarter' && $scope.card_quarter.departments) {
                        loadBudgetQuarterDepartments()
                    }
                }, loadBudgetQuarterUsers = () => {
                    $http.post('api/schedule/calendar/budget', {
                        department_id: $scope.department.id,
                        view: $scope.view.type,
                        start: $scope.view.start.format('YYYY-MM-DD'),
                        end: $scope.view.end.format('YYYY-MM-DD')
                    }).then(res => {
                        if($scope.view.type === 'quarter') {
                            $scope.budgets.users = res.data

                            if($scope.budgets.users.length) {
                                if($scope.quarter_budget.user_id > 0) {
                                    let found = false

                                    angular.forEach($scope.budgets.users, budget => {
                                        if(budget.user_id === $scope.quarter_budget.user_id) {
                                            $scope.quarter_budget.users = budget

                                            found = true
                                        }
                                    })

                                    if(!found) {
                                        $scope.quarter_budget.users = $scope.budgets.users[0]
                                        $scope.quarter_budget.user_id = $scope.quarter_budget.users.user_id
                                    }
                                } else {
                                    $scope.quarter_budget.users = $scope.budgets.users[0]
                                    $scope.quarter_budget.user_id = $scope.quarter_budget.users.user_id
                                }
                            }
                        } else {
                            $scope.budgets = res.data
                        }

                        $scope.loading = 'hide'
                        $timeout(() => {
                            $scope.$emit('recalc-top')
                        }, 1000)
                    }, () => {
                        $scope.loading = 'error'
                    })
                }, loadBudgetQuarterDepartments = () => {
                    $scope.budgets.departments = {}
                    $scope.quarter_budget.departments = {}
                    $scope.loading_budget.departments = 'loading'

                    $http.post('api/schedule/calendar/budget/departments', {
                        department_id: $scope.department.id,
                        start: $scope.view.start.format('YYYY-MM-DD'),
                        end: $scope.view.end.format('YYYY-MM-DD')
                    }).then(res => {
                        $scope.budgets.departments = res.data

                        if($scope.budgets.departments.length) {
                            $scope.quarter_budget.departments = $scope.budgets.departments[0]
                        }

                        $scope.loading_budget.departments = 'hide'

                        $timeout(() => {
                            $scope.$emit('recalc-top')
                        }, 1000)
                    }, () => {
                        $scope.loading_budget.departments = 'error'
                    })
                }, loadEvents = () => {
                    $http.post('api/schedule/calendar/events', {
                        department_id: $scope.department.id,
                        view: $scope.view.type,
                        start: $scope.view.start.format('YYYY-MM-DD'),
                        end: $scope.view.end.format('YYYY-MM-DD')
                    }).then(res => {
                        $scope.activity = res.data.activity

                        if($scope.view.type === 'day') {
                            $scope.ratio.day = res.data.ratio;
                            $scope.activity = $scope.activity[Object.keys($scope.activity)[0]]
                        }

                        $scope.events = res.data.events;
                        $scope.working_hours = res.data.working_hours;
                        $scope.week_working_hours = res.data.week_working_hours;
                        $scope.quarter_working_hours = res.data.quarter_working_hours;
                        $scope.movements = res.data.movements
                        $scope.movementsLastYear = res.data.movementsLastYear

                        $scope.loading = 'hide'

                        $timeout(() => {
                            $scope.$emit('recalc-top')
                        }, 1000)

                        $http.post('api/schedule/calendar/holidays', {
                            start: angular.copy($scope.view.start).startOf('w').format('YYYY-MM-DD'),
                            end: angular.copy($scope.view.end).endOf('w').format('YYYY-MM-DD')
                        }).then(res => {
                            $scope.holidays = res.data
                            fillDays($scope.view.start.clone().startOf('w'))
                        })
                    }, () => {
                        $scope.loading = 'error'
                    })
                }, loadData = () => {
                    $scope.loading = 'loading'

                    $scope.specialEvents = BudgetHelper.getSpecialEvents($scope.view.start)

                    if($scope.view.budget) {
                        loadBudget()
                    } else {
                        loadEvents()
                    }
                }, loadUsers = () => {
                    $http.get('api/schedule/calendar/users/list/' + $scope.department.id).then(res => {
                        $scope.users = res.data
                    }, () => {
                        $scope.$emit('error', 'schedule.notify.error.USERSLIST')
                    })
                }

                loadUsers()

                window.schedule = $scope

                $scope.changeUserBudget = () => {
                    $scope.quarter_budget.user_id = $scope.quarter_budget.users.user_id
                }

                $scope.isToday = () => {
                    let now = moment()

                    return $scope.view.start.diff(now, 'days') === 0
                }

                $scope.isPast = () => {
                    let now = moment()

                    return $scope.view.start.diff(now, 'days') < 0
                }

                $scope.openEditForm = event => {
                    if (!uac.permission('schedule.edit')) {
                        return
                    }

                    dialogs.confirm(null, {
                        template: 'app/views/directives/schedule/edit.html',
                        className: 'ngdialog-theme-plain',
                        controller: ['$scope', '$rootScope', 'formValidate', function(d_scope, d_root_scope, formValidate) {
                            d_scope.formValidate = formValidate

                            d_scope.hours = []
                            for(let i = 0; i < 24; ++i) {
                                d_scope.hours.push(i < 10 ? '0' + i : i.toString())
                            }
                            d_scope.minutes = ['00', '30']

                            d_scope.users = $scope.users

                            let start = typeof event.start !== 'undefined' ? moment(event.start) :
                                    angular.copy($scope.view.start).set({hour: parseInt($scope.working_hours.start.hour),
                                        minute: parseInt($scope.working_hours.start.minute), second: 0}),
                                end = typeof event.end !== 'undefined' ? moment(event.end) :
                                    angular.copy($scope.view.end).set({hour: parseInt($scope.working_hours.end.hour),
                                        minute: parseInt($scope.working_hours.end.minute), second: 0}),
                                edit = Object.keys(event).includes('id'),

                                working_hours_start_date = $scope.view.start.clone()
                                    .set({hour: parseInt($scope.working_hours.start.hour), minute: parseInt($scope.working_hours.start.minute), second: 0}),

                                working_hours_end_date = $scope.view.start.clone()
                                    .set({hour: parseInt($scope.working_hours.end.hour), minute: parseInt($scope.working_hours.end.minute), second: 0})

                            d_scope.form = {
                                id: edit ? event.id : 0,
                                editable: event.editable,
                                department_id: $scope.department.id,
                                user: {
                                    id: event.user_id
                                },
                                type: event.type,
                                ref_id: event.ref_id,
                                start: {
                                    date: start.format('YYYY-MM-DD'),
                                    hour: start.format('HH'),
                                    minute: start.format('mm')
                                },
                                end: {
                                    date: start.diff(working_hours_end_date, 'minutes') >= 0 && start.clone().add(30, 'minutes').format('HH:mm:ss') === '00:00:00'
                                        ? start.add(1, 'd').format('YYYY-MM-DD') : end.format('YYYY-MM-DD'),
                                    hour: edit ? end.format('HH') : working_hours_start_date.diff(start, 'minutes') > 0 ? $scope.working_hours.start.hour :
                                        (start.diff(working_hours_end_date, 'minutes') >= 0 ? start.clone().add(30, 'minutes').format('HH') : $scope.working_hours.end.hour),
                                    minute: edit ? end.format('mm') : working_hours_start_date.diff(start, 'minutes') > 0 ? $scope.working_hours.start.minute :
                                        (start.diff(working_hours_end_date, 'minutes') >= 0 ? start.clone().add(30, 'minutes').format('mm') : $scope.working_hours.end.minute)
                                }
                            }

                            d_scope.edit = () => {
                                d_scope.closeThisDialog()

                                $scope.loading = 'loading'

                                $http.post('api/schedule/calendar/add', d_scope.form).then(res => {
                                    if(res.data.errors.length) {
                                        res.data.errors.forEach(error => {
                                            $scope.$emit('error', 'schedule.directive.notify.error.' + error)
                                        })
                                    }

                                    loadData()
                                    $scope.loading = 'hide'
                                }, error => {
                                    if(error.status === 412) {
                                        $scope.loading = 'hide'

                                        $scope.$emit('notify', {
                                            translate: false,
                                            message: error.data.message,
                                            status: 'danger'
                                        })
                                    } else {
                                        $scope.$emit('error', 'schedule.directive.notify.error.EDITADD')

                                        $scope.loading = 'error'
                                    }
                                })
                            }

                            d_scope.deleteEvent = id => {
                                d_scope.closeThisDialog()

                                $scope.loading = 'loading'

                                $http.post('api/schedule/calendar/delete', {
                                    id: id
                                }).then(() => {
                                    loadData()
                                    $scope.loading = 'hide'
                                }, error => {
                                    if(error.status === 412) {
                                        $scope.loading = 'hide'

                                        $scope.$emit('notify', {
                                            translate: false,
                                            message: error.data.message,
                                            status: 'danger'
                                        })
                                    } else {
                                        $scope.$emit('error', 'schedule.directive.notify.error.DELETEEVENTS')

                                        $scope.loading = 'error'
                                    }
                                })
                            }

                            d_root_scope.$on('dialog-close', () => {
                                d_scope.closeThisDialog()
                            })
                        }],
                    })
                }

                $scope.render_navigation = false

                $scope.goRenderNavigation = () => {
                    $scope.render_navigation = true

                    $timeout(() => {
                        $scope.render_navigation = false
                    }, 100)
                }

                $scope.getUserWeekDayLength = events => {
                    let minHour = 24,
                        maxHour = 0

                    if(!events.length) {
                        return false
                    }

                    if(events.length === 1 && events[0].type_name === 'U') {
                        return [{ class: 'schedule-color-3' }]
                    }

                    angular.forEach(events, event => {
                        let start = moment(event.start).hour(),
                            end = moment(event.end).hour()

                        if (start < minHour) minHour = start
                        if (end > maxHour) maxHour = end
                    })

                    maxHour -= $scope.working_hours.start.hour
                    minHour -= $scope.working_hours.start.hour

                    let columns = $scope.working_hours.end.hour - $scope.working_hours.start.hour,
                        result = []

                    for(let i = 0; i < columns; i++) {
                        result.push({
                            class: (i >= minHour && i < maxHour) ? 'schedule-color-1' : 'schedule-color-off'
                        })
                    }
                    return result
                }

                $scope.getUserWeekDay = events => {
                    let startHour = parseInt($scope.working_hours.start.hour),
                        endHour   = parseInt($scope.working_hours.end.hour),
                        result    = {
                            alert: false,
                            columns: {}
                        }

                    if(!events.length) {
                        return false
                    }

                    let key = ''
                    for(let i = startHour - 1; i < endHour + 1; i++) {
                        key = (i < 10) ? '0' + i : '' + i
                        result.columns[key] = {
                            class: 'schedule-color-off',
                            events: []
                        }
                    }

                    angular.forEach(events, event => {
                        let start = moment(event.start).hour(),
                            end = moment(event.end).clone().add(-1, 'm').hour()

                        let key = ''
                        for (let i = start; i <= end; i++) {
                            key = (i < 10) ? '0' + i : '' + i
                            if (result.columns[key]) {
                                result.columns[key].class = 'schedule-color-' + event.type
                                result.columns[key].events.push(event)
                            } else {
                                result.alert = true
                            }
                        }
                    })

                    return result
                }

                $scope.goTurnOverAmbitious = () => {
                    dialogs.confirm(null, {
                        template: 'app/views/directives/schedule/ambitious.html',
                        className: 'ngdialog-theme-plain ngdialog-full-width',
                        data: {
                            department_id: $scope.department.id,
                            title: $scope.view.label.main,
                            users: $scope.budgets.users
                        },
                        controller: 'budget.ambitious'
                    }).then(() => {
                        loadData()
                    })
                }

                $scope.goPercent = () => {
                    dialogs.confirm(null, {
                        template: 'app/views/directives/schedule/percent.html',
                        className: 'ngdialog-theme-plain ngdialog-full-width',
                        data: {
                            title: $scope.view.label.main,
                            department_id: $scope.department.id
                        },
                        controller: 'budget.percent'
                    }).then(() => {
                        loadData()
                    })
                }

                $scope.generateQuarterGrouped = () => {
                    $scope.loading_budget.grouped = 'loading'

                    $http.post('api/schedule/calendar/budget/grouped', {
                        start: $scope.view.start.format('YYYY-MM-DD'),
                        end: $scope.view.end.format('YYYY-MM-DD'),
                        department_ids: $scope.grouped.department_ids
                    }).then(res => {
                        $scope.loading_budget.grouped = 'hide'

                        $scope.quarter_budget.grouped = res.data

                        $timeout(() => {
                            $scope.$emit('recalc-top')
                        }, 1000)
                    }, () => {
                        $scope.loading_budget.grouped = 'hide'

                        $scope.$emit('error', 'schedule.budget.quarter.grouped.notify.error.LOAD')
                    })
                }

                $scope.refreshQuarterGrouped = () => {
                    $http.post('api/schedule/calendar/budget/refresh', {
                        year: $scope.chart_department.year,
                        quarter: $scope.chart_department.quarter
                    }).then(() => {
                        $scope.$emit('success', 'schedule.budget.quarter.grouped.notify.REFRESH')
                    }, () => {
                        $scope.$emit('error', 'schedule.budget.quarter.grouped.notify.error.LOAD')
                    })
                }

                $scope.riseOrFallInPercents = (a, b) => {
                    return a ? Math.abs((((b - a) / a) * 100).toFixed(1)) : 0
                }

                $scope.textColor = (before, current) => {
                    if(before > current) {
                        return 'text-danger bold'
                    }

                    return 'text-success bold'
                }

                $scope.textColorBetween = (below, current, above) => {
                    if (current > above) {
                        return 'text-success bold'
                    }

                    if (current < below) {
                        return 'text-danger bold'
                    }

                    return ''
                }

                $scope.updateDepartment = () => {
                    loadUsers()

                    loadData()
                }

                $scope.updateData = () => {
                    loadData()
                }

                $scope.getDepartmentIds = ids => {
                    $scope.grouped.department_ids = ids
                }

                $scope.goPlanningBudget = () => {
                    let filename = $scope.chart_department.quarter + 'Q' + $scope.chart_department.year.toString().substr(-2) +
                        '_planowanie_' + $scope.department.login + '_' + moment().format('YYYY-MM-DD') + '_' +
                        parseInt(Math.random() * 100000) + '.xls'

                    EventBus.$emit('ng:emit', {
                        name: 'downloadable',
                        data: {
                            name: 'harmonogram/budget/planning/' + filename,
                            status: true
                        }
                    })

                    $http.post('api/budget/planning/excel', {
                        department_id: $scope.department.id,
                        year: $scope.chart_department.year,
                        quarter: $scope.chart_department.quarter,
                        filename: filename
                    }).catch(() => {
                        $scope.$emit('error', 'schedule.budget.excel.notify.error.LOAD')
                    })
                }
            }]
        }
    }
])
