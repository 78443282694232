angular.module('eOpti.directives').directive('animateOn', [
    '$timeout',
    function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                'animateOn': '=',
            },
            link: function (scope, elem, attrs) {
                var $elem = $(elem),
                    delay = $elem.data('delay') || 100,
                    animo = $elem.data('animate') || 'bounce',
                    watch = $elem.data('animateOn');

                var animate = function (element) {
                    if (!element.hasClass('anim-running')) {
                        element.addClass('anim-running');

                        $timeout(function () {
                            element
                                .addClass('anim-done')
                                .animo({
                                    animation: animo,
                                    duration: 0.7
                                })
                        }, delay);
                    }
                };

                scope.$watch(function () {
                    return watch;
                }, function (newVal, oldVal) {
                    if (newVal != oldVal && newVal) {
                        animate($elem);
                    }
                }, true);
            }
        }
    }
]);
