angular.module('eOpti.directives.inputs.select').directive('single', [
    function () {
        return {
            restrict: 'A',
            scope: {
                value: '=ngModel',
                ngDisabled: '=?'
            },
            link: function (scope, elem) {
                scope.$watch(
                    function () {
                        return elem.find('option').length;
                    }, function (nVal) {
                        angular.element(elem).removeAttr('disabled');

                        if (nVal === 2) {
                            if (angular.element(elem.find('option')[0]).val() === '?') {
                                scope.value = {
                                    id: parseInt(angular.element(elem.find('option')[1]).val())
                                };

                                angular.element(elem).attr('disabled', 'disabled');
                            }
                        } else if (nVal === 1) {
                            angular.element(elem).attr('disabled', 'disabled');
                        }

                        if(typeof scope.ngDisabled !== 'undefined' && scope.ngDisabled) {
                            angular.element(elem).attr('disabled', 'disabled');
                        }
                    }
                )
            }
        };
    }]);
