angular.module('eOpti.directives.inputs.buttons').directive('btn', [
    '$http', 'dialogs', '$parse',
    function ($http, dialogs, $parse) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: true,
            templateUrl: 'app/views/directives/inputs/btn.html',
            link: function(scope, elem, attr, ctrl, transclude) {
                if(Object.keys(attr).includes('save')) {
                    scope.type = 'btn-success'
                    scope.icon = 'fa fa-save'
                    scope.name = 'default.SAVE'
                    scope.translate = true
                } else {
                    let allowType = ['default', 'primary', 'info', 'warning', 'danger', 'success', 'green', 'inverse']

                    scope.type = 'btn-purple'

                    angular.forEach(attr, (value, name) => {
                        if (allowType.includes(name)) {
                            scope.type = 'btn-' + allowType[allowType.indexOf(name)]
                        }
                    })

                    scope.icon = attr.icon

                    scope.name = transclude().context.innerText

                    scope.translate = attr.lang || 'true'

                    scope.right = Object.keys(attr).includes('right')
                }

                if(attr.text) {
                    scope.message = attr.text + '.MESSAGE'
                    scope.yes = attr.text + '.YES'
                    scope.no = attr.text + '.NO'
                } else if(attr.message) {
                    scope.message = attr.message
                    scope.yes = attr.yes ? attr.yes : 'default.bool.YES'
                    scope.no = attr.no ? attr.no : 'default.bool.NO'
                }

                if(typeof scope.message === 'string') {
                    let fn = $parse(attr.confirm)

                    elem.on('click', () => {
                        dialogs.confirm(scope.message, {
                            className: 'ngdialog-theme-plain',
                            data: {
                                confirm: scope.yes,
                                confirmIcon: 'fa fa-fw fa-check',
                                cancel: scope.no
                            }
                        }, scope.translate).then(() => {
                            fn(scope)
                        })
                    })
                }
            }
        }
    }
]);
