angular.module('eOpti.directives.optometrist.extra').directive('redLensTest', [
    function () {
        return {
            restrict: 'E',
            templateUrl: 'app/views/directives/optometrist/extra/red-lens-test.html',
            replace: true,
            transclude: true,
            scope: {
                side: '@',

                model: '=',
                field: '@'
            },
            controller: [
                '$scope', '$enum',
                function ($scope, $enum) {
                    var ucFirst = function (string) {
                        return string.charAt(0).toUpperCase() + string.slice(1);
                    };

                    $scope.tab =[];

                    $enum.get('InterviewParalyticRed' + ucFirst($scope.side)).then(function(res) {
                        $scope.tab = res;

                        angular.forEach($scope.tab, function(tab) {
                            var n = tab.name.lastIndexOf('.');

                            tab.short = tab.name.substring(n + 1);
                        });
                    });

                    $scope.setData = function(value) {
                        if($scope.model[$scope.field] !== value) {
                            $scope.model[$scope.field] = value;
                        } else {
                            $scope.model[$scope.field] = 0;
                        }
                    }

                }
            ]
        }
    }
]);
