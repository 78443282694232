angular.module('eOpti.directives').directive('panel', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        templateUrl: 'app/views/directives/panel.html',
        link: function (scope, elem, attr, ctrl, transclude) {
            let allowType = ['default', 'primary', 'info', 'warning', 'danger', 'success', 'green', 'inverse', 'pink', 'purple'],
                allowToggle = ['hide', 'show']

            scope.toggle = true

            scope.icon = 'fa fa-navicon'
            scope.heading = ''

            scope.type = 'panel-primary'

            angular.forEach(attr, (value, name) => {
                if(allowType.includes(name)) {
                    scope.type = 'panel-' + allowType[allowType.indexOf(name)]
                } else if (name === 'icon') {
                    scope.icon = value
                } else if (name === 'heading') {
                    scope.heading = value
                } else if (allowToggle.includes(name)) {
                    scope.toggle = name
                } else if(name === 'none') {
                    scope.none = value
                }
            })

            transclude(scope.$parent, clone => {
                elem.find('.panel-container').append(clone)
            })
        },
        controller: ['$scope', function ($scope) {
            $scope.cursor = () => {
                let cursor = $scope.toggle === 'hide' || $scope.toggle === 'show' ? 'pointer' : 'default'

                return {
                    cursor: cursor
                }
            }

            $scope.setToggle = () => {
                if ($scope.toggle === 'show') {
                    $scope.toggle = 'hide'
                } else if ($scope.toggle === 'hide') {
                    $scope.toggle = 'show'
                }
            }
        }]
    }
})