angular.module('eOpti.directives.optometrist').directive('eyeDiseases', [
    '$http',
    'dialogs',
    'ngDialog',
    function ($http, dialogs, ngDialog) {
        return {
            restrict: 'E',
            template: '<span ng-click="showDialog()" ng-transclude></span>',
            replace: true,
            transclude: true,
            scope: {
                model: '=',
                diseases: '@',
                side: '@',
                ngDisabled: '=?'
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showDialog = function () {
                        var container = {
                            diseases: angular.copy($scope.model[$scope.diseases]),
                            side: $scope.side
                        };

                        var parts = {
                            r: false,
                            l: false
                        };

                        var options = {
                            closeByDocument: false,
                            showClose: false,
                            overlay: false,
                            className: 'correction-dialog',
                            template: 'app/views/directives/optometrist/eye-diseases.html',
                            disableAnimation: true,
                            data: {
                                model: container
                            },
                            controller: [
                                '$scope',
                                function ($innerScope) {
                                    var reset = function(side) {
                                        angular.forEach($innerScope.ngDialogData.model.diseases, function (disease) {
                                            if (typeof side === 'undefined' || side === 'r') {
                                                disease.pivot.r_zone_1 = false;
                                                disease.pivot.r_zone_2 = false;
                                                disease.pivot.r_zone_3 = false;
                                                disease.pivot.r_zone_4 = false;
                                                disease.pivot.r_zone_5 = false;
                                                disease.pivot.r_zone_6 = false;
                                                disease.pivot.r_zone_7 = false;
                                                disease.pivot.r_zone_8 = false;
                                                disease.pivot.r_zone_9 = false;
                                            }

                                            if (typeof side === 'undefined' || side === 'l') {
                                                disease.pivot.l_zone_1 = false;
                                                disease.pivot.l_zone_2 = false;
                                                disease.pivot.l_zone_3 = false;
                                                disease.pivot.l_zone_4 = false;
                                                disease.pivot.l_zone_5 = false;
                                                disease.pivot.l_zone_6 = false;
                                                disease.pivot.l_zone_7 = false;
                                                disease.pivot.l_zone_8 = false;
                                                disease.pivot.l_zone_9 = false;
                                            }
                                        });
                                    };

                                    reset();

                                    $innerScope.goPreviousIndex = function () {
                                        reset('r');

                                        $innerScope.ngDialogData.model.side = 'r';

                                        parts.l = true;
                                    };

                                    $innerScope.goNextIndex = function () {
                                        reset('l');

                                        $innerScope.ngDialogData.model.side = 'l';

                                        parts.r = true;
                                    };

                                    $innerScope.closeDialog = function(data) {
                                        parts[$innerScope.ngDialogData.model.side] = true;

                                        $innerScope.closeThisDialog(data);
                                    };
                                }
                            ]
                        };

                        if ($scope.ngDisabled !== 'undefined' && !$scope.ngDisabled) {
                            var dialog = ngDialog.open(options);

                            dialog.closePromise.then(function (data) {
                                if (data.value) {
                                    $scope.model[$scope.diseases] = container.diseases;

                                    $scope.model.contactLens.disease_r_checked = parts.r;
                                    $scope.model.contactLens.disease_l_checked = parts.l;
                                }
                            });
                        }
                    };
                }
            ]
        }
    }
]);
