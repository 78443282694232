angular.module('eOpti.directives').directive('clipboard', [
    'clipboardService',
    function (clipboardService) {
        return {
            restrict: 'A',
            scope: {
                clipboard: '='
            },
            link: function (scope, element, attrs) {
                element.click(function () {
                    var copied = clipboardService.copy(scope.clipboard);
                    scope.$emit('notify', {
                        translate: false,
                        message: copied ? 'Skopiowano "' + scope.clipboard + '" do schowka!' : 'Błąd podczas kopiowania do schowka!',
                        status: copied ? 'success' : 'danger'
                    });
                });
            }
        }
    }
]);
