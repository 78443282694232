angular.module('eOpti.directives.dialogs').controller('budget.percent', [
    '$scope', '$http', 'uac',
    function ($scope, $http, uac) {
        $scope.title = $scope.ngDialogData.title;
        $scope.department_id = $scope.ngDialogData.department_id;
        $scope.time = {
            year: 2000 + parseInt($scope.title.split('Q')[1]),
            quarter: parseInt($scope.title.split('Q')[0])
        };

        $scope.data = {
            main_value: 0,
            weeks: []
        };

        $scope.disabled = !uac.permission('schedule.admin');

        $http.post('api/budget/percent/get', {
            department_id: $scope.department_id,
            year: $scope.time.year,
            quarter: $scope.time.quarter
        }).then(function(res) {
            $scope.data.weeks = res.data;

            $scope.data.weeks.forEach(function(week) {
                $scope.data.main_value += week.all;
            });

            $scope.loading = 'hide';
        }, function() {
            $scope.$emit('error', 'schedule.budget.percent.notify.error.LOAD');

            $scope.loading = 'hide';
        });

        $scope.$watch('data.main_value', function(nVal, oVal) {
            if(nVal !== oVal) {
                $scope.data.weeks.forEach(function(week) {
                    week.all = $scope.data.main_value * week.percent_all / 100;
                    week.optics = $scope.data.main_value * week.percent_optics / 100;
                });
            }
        });

        $scope.savePercent = function() {
            $scope.loading = 'loading';

            $http.post('api/budget/percent/add-edit', {
                time: $scope.time,
                department_id: $scope.department_id,
                weeks: $scope.data.weeks
            }).then(function() {
                $scope.confirm();

                $scope.loading = 'hide';

                $scope.$emit('error', 'schedule.budget.percent.notify.ADD');
            }, function() {
                $scope.loading = 'hide';

                $scope.$emit('error', 'schedule.budget.percent.notify.error.ADD');
            });
        };
    }
]);
