angular.module('eOpti.directives').directive('layoutMap', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            departmentId: '='
        },
        templateUrl: 'app/views/directives/layoutMap.html',
        link: function (scope, elem, attr, ctrl, transclude) {
            scope.width = attr.width || '100%'
            scope.height = attr.height || false

            window.el = elem

            let svg = angular.element(elem[0].getElementsByTagName('svg').floor)
            svg.attr('width', scope.width)
            if (scope.height) {
                svg.attr('height', scope.height)
            }

            scope.blank = attr.blank || false
        },
        controller: [
            '$scope',
            '$http',
            '$state',
            'layoutClasses',
            function ($scope, $http, $state, layoutClasses) {



                $scope.rnd = Math.random() * 10000

                $scope.openGrid = () => {
                    if (!!$scope.blank) {
                        let url = $state.href('app.layout.grid', {departmentId: $scope.departmentId})
                        window.open(url, '_blank')
                    } else {
                        $state.go('app.layout.grid', {departmentId: $scope.departmentId})
                    }
                }

                $scope.grid = {
                    width: 8,
                    height: 6,
                    getWidth: function () {
                        return this.width * this.small.width * this.big.countX + 1
                    },
                    getHeight: function () {
                        return this.height * this.small.height * this.big.countY + 1
                    },
                    viewBox: function () {
                        return '0 0 ' + this.getWidth() + ' ' + this.getHeight()
                    },
                    small: {
                        width: 10,
                        height: 10
                    },
                    big: {
                        countX: 10,
                        countY: 10
                    },
                    smallPath: function () {
                        return 'M ' + $scope.grid.small.width + ' 0 L 0 0 0 ' + $scope.grid.small.height
                    },
                    bigPath: function () {
                        return 'M ' + ($scope.grid.small.width * $scope.grid.big.countX) + ' 0 L 0 0 0 ' + ($scope.grid.small.height * $scope.grid.big.countY)
                    }
                }

                let emptyFn      = () => {
                }
                let functions    = {
                    room: {
                        applyFn: function (fn) {
                            $scope.$apply(fn)
                        },
                        roundingFn: emptyFn,
                        activateFn: emptyFn
                    },
                    object: {
                        applyFn: function (fn) {
                            $scope.$apply(fn)
                        },
                        roundingFn: emptyFn,
                        roundingAngleFn: emptyFn,
                        activateFn: emptyFn,
                        toggle: emptyFn
                    }
                }
                let assignConfFn = function (conf, type) {
                    return Object.assign({}, functions[type], conf)
                }
                let fillData     = function (data, grid) {
                    grid.width  = data.grid.width
                    grid.height = data.grid.height

                    for (let i = 0; i < data.objects.length; i++) {
                        let conf        = assignConfFn(data.objects[i], 'object')
                        data.objects[i] = new layoutClasses.objects[conf.type](conf)
                    }

                    for (let i = 0; i < data.rooms.length; i++) {
                        let conf      = assignConfFn(data.rooms[i], 'room')
                        data.rooms[i] = new layoutClasses.rooms[conf.type](conf)
                    }

                    return data
                }
                $scope.fetchData = function () {
                    $http.get('api/layout/room/' + $scope.departmentId).then(function (result) {
                        $scope.data      = fillData(result.data, $scope.grid)
                        $scope.detailsId = result.data.detailsId
                    }, function(error) {
                        if(error.status === 403) {
                            $scope.$emit('error', error.data.message)
                        }
                    })
                }
                $scope.fetchData()

            }
        ]
    }
})
