angular.module('eOpti.directives.dialogs').directive('image', [
    '$translate',
    'dialogs',
    function ($translate, dialogs) {
        return {
            restrict: 'A',
            scope: {
                image: '@'
            },
            link: function (scope, elem) {
                $translate('default.title.PREVIEW').then(text => {
                    elem.attr('title', text)
                })

                elem.bind('click', () => {
                    dialogs.confirm(null, {
                        template: 'app/views/_dialogs/preview-image.html',
                        className: 'ngdialog-theme-plain',
                        data: {
                            className: 'ngdialog-theme-plain',
                            link: scope.image
                        }
                    })
                })
            }
        };
    }
]);
