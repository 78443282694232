angular.module('eOpti.directives').directive('temporaryLink', ['link',
    function (link) {
        return {
            restrict: 'A',
            scope: {
                temporaryLink: '=',
                url: '@'
            },
            link: function (scope, element) {
                element.click(function() {
                    link.activate(scope.url + '/' + scope.temporaryLink).then(function(res) {
                        window.open(res.link, '_blank');
                    }, function() {
                        $scope.$emit('error', 'default.notify.error.LOADDOC');
                    });
                });
            }
        }
    }
]);
