angular.module('eOpti.directives').directive('autoSave', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/views/directives/auto-save.html',
            scope: {
                model: '=',
                fields: '@',
                callback: '&',
                delay: '@',
                wait: '=?',
                return: '=?',
                mirror: '=?',
                change: '=?',
                toggle: '=?'
            },
            controller: [
                '$scope',
                '$timeout',
                'error',
                function ($scope, $timeout, errorValidate) {
                    if(!$scope.mirror) {
                        var modelStates = {
                            changed: 'has-warning',
                            saved: 'has-success',
                            error: 'has-error'
                        };

                        $scope.stop = false;

                        $scope.model._autosave = {
                            _state: 'init'
                        };

                        var timeout = null;
                        var debounceSave = function (newVal, oldVal) {
                            var compare =
                                typeof newVal === 'object' && typeof oldVal === 'object' ?
                                    !angular.equals(newVal, oldVal) : newVal!= oldVal;

                            if (!$scope.stop && compare) {
                                if ($scope.fields) {
                                    $scope.model._autosave[newVal.field] = modelStates.changed;
                                }

                                if ($scope.model._autosave._state == 'saving') {
                                    timeout = $timeout(function () {
                                        debounceSave(newVal, oldVal);
                                    }, 3000);

                                    return;
                                }

                                $scope.model._autosave._state = 'debouncing';

                                if (timeout) {
                                    $timeout.cancel(timeout);
                                }

                                timeout = $timeout(function () {
                                    call(newVal);

                                }, $scope.delay * 1000);
                            } else {
                                $scope.stop = false;
                            }
                        };

                        var call = function (newVal, oldVal) {
                            if($scope.wait) { //czekaj na bony …
                                setTimeout(() => {
                                    call(newVal, oldVal)
                                }, 1000)

                                return false
                            }

                            $scope.model._autosave._state = 'saving';

                            $scope.callback().then(function (res) {
                                if($scope.return) {
                                    var tmp = res.data;
                                    tmp._autosave = {_state: 'saved'};

                                    $scope.stop = true;

                                    angular.forEach($scope.model, function(value, key) {
                                        $scope.model[key] = tmp[key];
                                    });

                                    $scope.change.info = true;

                                    $scope.toggle = !$scope.toggle;
                                } else {
                                    $scope.model._autosave._state = 'saved';
                                }

                                if($scope.fields) {
                                    updateState(modelStates.saved);
                                }
                            }, function (error) {
                                $scope.model._autosave._state = 'error';

                                if($scope.fields) {
                                    updateState(modelStates.error);
                                }

                                $scope.$emit('errorAutoload');

                                errorValidate.request(error);
                            }).finally(function () {
                                $timeout(function () {
                                    $scope.model._autosave._state = 'init';
                                    if($scope.fields) {
                                        updateState(false);
                                    }
                                }, $scope.delay * 1000);
                            });
                        };

                        var updateState = function (state) {
                            angular.forEach($scope.model._autosave, function (val, key) {
                                if (key != '_state') {
                                    if (state) {
                                        $scope.model._autosave[key] = state;
                                    } else {
                                        delete $scope.model._autosave[key];
                                    }
                                }
                            });
                        };

                        if ($scope.fields) {
                            var fields = $scope.fields.split(/[\s]+/).join().split(',');

                            angular.forEach(fields, function (field) {
                                $scope.$watch(function () {
                                    return {
                                        model: $scope.model[field],
                                        field: field
                                    }
                                }, debounceSave, true);
                            });
                        } else {
                            $scope.$watch(function () {
                                var modelWoInit = angular.copy($scope.model);

                                delete modelWoInit._autosave;

                                return {
                                    model: modelWoInit
                                }
                            }, debounceSave, true);
                        }
                    }
                }
            ]
        }
    }
]);