angular.module('eOpti.directives').directive('sticky', [
    '$window',
    function ($window) {
        return {
            link: function(scope, element, attrs) {
                let offsetY = 80
                let $win = angular.element($window);

                if (scope._stickyElements === undefined) {
                    scope._stickyElements = [];

                    $win.bind("scroll.sticky", function(e) {
                        var pos = $win.scrollTop() + offsetY;
                        for (var i=0; i<scope._stickyElements.length; i++) {

                            var item = scope._stickyElements[i];

                            if (!item.isStuck && pos > item.start) {
                                item.element.addClass("stuck");
                                item.isStuck = true;

                                if (item.placeholder) {
                                    item.placeholder = angular.element("<div></div>")
                                        .css({height: item.element.outerHeight() + "px"})
                                        .insertBefore(item.element);

                                    element.css({width: item.placeholder.outerWidth() + 'px'})
                                }
                            }
                            else if (item.isStuck && pos < item.start) {
                                item.element.removeClass("stuck");
                                item.isStuck = false;

                                if (item.placeholder) {
                                    item.placeholder.remove();
                                    item.placeholder = true;

                                    element.css({width: ''})
                                }
                            }
                        }
                    });

                    var recheckPositions = function() {
                        for (var i=0; i<scope._stickyElements.length; i++) {
                            var item = scope._stickyElements[i];
                            if (!item.isStuck) {
                                item.start = item.element.offset().top;
                            } else if (item.placeholder) {
                                item.start = item.placeholder.offset().top;
                                item.element.css({width: item.placeholder.outerWidth() + 'px'});
                            }
                        }
                    };
                    $win.bind("load", recheckPositions);
                    $win.bind("resize", recheckPositions);
                }

                var item = {
                    element: element,
                    isStuck: false,
                    placeholder: attrs.usePlaceholder !== undefined,
                    start: element.offset().top
                };

                scope._stickyElements.push(item);

            }
        };
    }
]);
