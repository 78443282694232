angular.module('eOpti.directives').directive('getTopFrom', [
    '$window',
    '$timeout',
    '$interval',
    '$rootScope',
    function ($window, $timeout, $interval, $rootScope) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {

            let getHeightAndApply = () => {
                let id = attrs.getTopFrom
                let height = $('#' + id).outerHeight(true)

                $(elem).css({
                    top: height + 'px'
                })
            }

            $window.addEventListener('resize', getHeightAndApply)
            scope.$on('$destroy', () => {
                $window.removeEventListener('resize', getHeightAndApply)
            })
            $rootScope.$on('recalc-top', () => {
                getHeightAndApply()
            })
        }
    }
}]);
