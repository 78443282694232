angular.module('eOpti.directives').directive('breadcrumb', function() {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/views/directives/breadcrumb.html',
        controller: ['$rootScope', '$scope', '$http', '$localStorage', 'uac', 'breadcrumb', 'ENV',
            function ($rootScope, $scope, $http, $localStorage, uac, breadcrumb, ENV) {
            $scope.breadcrumb = breadcrumb
            $scope.envTopic = ENV.topic

            if(uac.user) {
                $http.get('api/error/changelog/latest').then(res => {
                    if (typeof $localStorage['changelog'] !== 'undefined') {
                        $localStorage['changelog'].current = res.data.latest
                    } else {
                        $localStorage['changelog'] = {
                            current: res.data.latest,
                            previous: res.data.latest
                        }
                    }

                    $scope.changelog = $localStorage['changelog']
                }, () => {
                    $scope.$emit('error', 'bugtrack.changelog.notify.error.LOAD')
                })
            }

            $scope.root = $rootScope

            $rootScope.$on('changelog', (evt, data) => {
                $scope.changelog = data
            })
        }]
    }
})
