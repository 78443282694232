angular.module('eOpti.directives').directive('permission', ['uac', function(uac) {
    return {
        restrict: 'A',
        scope: {
            permission: '@'
        },
        link: function(scope, elem) {
            let reverse = scope.permission.charAt(0) === '!',
                permission = reverse ? scope.permission.substr(1) : scope.permission

            if((uac.permission(permission) && !reverse) || (!uac.permission(permission) && reverse)) {
                elem.removeAttr(scope.permission)
            } else {
                elem.remove()
            }
        }
    }
}])