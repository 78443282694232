angular.module('eOpti.directives').directive('upload', ['$auth', 'FileUploader', function($auth, FileUploader) {
    return {
        restrict: 'E',
        templateUrl: 'app/views/directives/upload.html',
        scope: {
            ngDisabled: '=?',
            loading: '=',
            model: '=',
            prefix: '@',
            onComplete: '&',
            onBeforeUpload: '&'
        },
        compile: function() {
            return {
                pre: function(scope, elem, attr) {
                    scope.loading = 'hide';
                    scope.file = null;

                    scope.upload = new FileUploader({
                        url: 'api/upload' + (Object.keys(attr).includes('picture') ? '/image' : ''),
                        alias: 'upload',
                        formData: [{
                            prefix: scope.prefix
                        }],
                        autoUpload: true,
                        headers: {
                            'Authorization': 'Bearer ' + $auth.getToken()
                        }
                    });

                    scope.upload.onBeforeUploadItem = function (item) {
                        if (scope.onBeforeUpload) {
                            scope.onBeforeUpload({item: item})
                        }
                    }

                    scope.upload.onAfterAddingFile = function () {
                        scope.loading = 'loading';

                        angular.forEach(scope.upload.queue, function (upload) {
                            scope.file = upload;
                        });
                    };

                    scope.upload.onCompleteItem = function (item, response) {
                        if (scope.onComplete) {
                            // jeśli onComplete zwróci jakąkolwiek wartość to nadpisujemy to co zwrócił serwer
                            var override = scope.onComplete({ response: response.info, old: scope.model })
                            if (override !== false && typeof override !== 'undefined') {
                                response.info = override
                            }
                        }

                        if (response.info && response.info !== 'fail') {
                            scope.model = response.info;

                            scope.loading = 'hide';
                        } else {
                            scope.upload.removeFromQueue(item);
                            scope.file = null;
                            scope.loading = 'error';
                        }
                    };
                }
            }
        }
    };
}]);
