angular.module('eOpti.directives.loaders').directive('loading', [function() {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/views/directives/loading.html',
        scope: {
            emptyText: '@',
            errorText: '@',
            status: '='
        },
        controller: ['$scope', function($scope) {
            $scope.loadingHide = false
            $scope.emptyHide = true
            $scope.errorHide = true

            $scope.changeToLoading = () => {
                $scope.loadingHide = false
                $scope.emptyHide = true
                $scope.errorHide = true
            }

            $scope.changeToHide = () => {
                $scope.loadingHide = true
                $scope.emptyHide = true
                $scope.errorHide = true
            }

            $scope.changeToEmpty = () => {
                $scope.loadingHide = true
                $scope.emptyHide = false
                $scope.errorHide = true
            }

            $scope.changeToError = () => {
                $scope.loadingHide = true
                $scope.emptyHide = true
                $scope.errorHide = false
            }

            $scope.$watch('status', newVal => {
                switch(newVal) {
                    case 'loading':
                        $scope.changeToLoading()
                        break

                    case 'hide':
                        $scope.changeToHide()
                        break

                    case 'empty':
                        $scope.changeToEmpty()
                        break

                    case 'error':
                        $scope.changeToError()
                }
            })
        }]
}}])