angular.module('eOpti.directives.inputs').directive('cellphoneinput', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/views/directives/inputs/cell-phone-input.html',
            require: '^?form',
            scope: {
                placeholder: '@',
                ngModelCountry: '=',
                ngModelNumber: '=',
                id: '@',
                nameCountry: '@',
                nameNumber: '@'
            },
            link: function (scope, element, attributes) {

            },
            controller: [
                '$scope', '$storage',
                function ($scope, $storage) {
                    $scope.country = {};
                    $scope.options = [];
                    $scope.countries = [];

                    $storage.get('countryCode').then(function(res) {
                        $scope.countries = res;
                    });
                }
            ]
        }
    }
]);