angular.module('eOpti.directives.optometrist').directive('optometristOsdiQuestionnaire', [
    'ngDialog',
    '$enum',
    function (ngDialog, $enum) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/views/directives/optometrist/osdi.html',
            scope: {
                model: '=',
                points: '=',
                ngDisabled: '='
            },
            controller: [
                '$scope',
                function ($scope) {

                    $scope.options = [];
                    $enum.get('InterviewContactLensQuestionnaireOption').then(function (res) {
                        $scope.options = res;
                        $scope.options.shift()
                    });

                    $scope.openQuestionnaireDialog = function () {
                        var options = {
                            closeByDocument: false,
                            showClose: false,
                            overlay: false,
                            className: 'correction-dialog',
                            template: 'app/views/directives/optometrist/osdi-dialog.html',
                            disableAnimation: true,
                            data: {
                                questions: angular.copy($scope.model),
                                options: $scope.options,
                                ngDisabled: $scope.ngDisabled
                            },
                            controller: [
                                '$scope',
                                function ($inner) {
                                    $inner.label = {
                                        pl: {
                                            questions: [
                                                'Czy w ostatnim tygodniu odczuwał/-a Pan/-i którykolwiek z następujących objawów?',
                                                'Czy problemy z oczami ograniczały Pana/Panią w ostatnim tygodniu przy wykonywaniu następujących czynności?',
                                                'Czy w ostatnim tygodniu odczuwał/a Pan/Pani dyskomfort ze strony oczy w którejś z wymienionych sytuacji?'
                                            ],
                                            label: ['Nigdy', 'Niekiedy', 'Przez połowę czasu', 'Przez większość czasu', 'Przez cały czas'],
                                            description: ['', 'raz w tygodniu', '2 - 3 / tydz.', '4 -6 / tydz.', '']
                                        },
                                        eng: {
                                            questions: [
                                                'Have you experienced any of the following symptoms in the last week?',
                                                'Have problems with your eyes restricted you last week while performing the following activities?',
                                                'In the last week did you feel discomfort from your eyes in any of the above situations?'
                                            ],
                                            label: ['Never', 'Seldom', 'Occasionally', 'Frequently', 'Always'],
                                            description: ['', 'once a week', '2 - 3 / week', '4 -6 / week', '']
                                        }
                                    };

                                    $inner.calculatePoints = function () {
                                        var points  = 0,
                                            checked = true;

                                        angular.forEach($inner.ngDialogData.questions, function (q) {
                                            if (q.pivot.option !== -1) {
                                                points += q.pivot.option
                                            } else {
                                                checked = false
                                            }
                                        });

                                        $inner.points = checked ? points : -1;
                                    };

                                    $inner.calculatePoints()
                                }
                            ]
                        };

                        var dialog = ngDialog.open(options);

                        dialog.closePromise.then(function (data) {
                            if (data.value) {
                                angular.forEach(data.value.questions, function (q1) {
                                    angular.forEach($scope.model, function (q2) {
                                        if (q1.id === q2.id) {
                                            q2.pivot.option = q1.pivot.option
                                        }
                                    })
                                });

                                $scope.points = data.value.points
                            }
                        });
                    }
                }
            ]
        }
    }
])
