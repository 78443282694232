angular.module('eOpti.directives').directive('datepickerOutput', [
    '$filter',
    function ($filter) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, controller) {
                controller.$parsers.push(function (value) {
                    return $filter('date')(value, attr.datepickerOutput, attr.datepickerTZ);
                });
            }
        }
    }
]);