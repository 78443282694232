angular.module('eOpti.directives.optometrist').directive('efron', [
    '$http',
    'dialogs',
    'ngDialog',
    function ($http, dialogs, ngDialog) {
        return {
            restrict: 'E',
            template: '<span ng-click="showDialog()" ng-transclude></span>',
            replace: true,
            transclude: true,
            scope: {
                model: '=',
                efron: '@',
                side: '@',
                ngDisabled: '=?'
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showDialog = function () {
                        var container = {
                            efron: angular.copy($scope.model[$scope.efron]),
                            side: $scope.side
                        };

                        var options = {
                            closeByDocument: false,
                            showClose: false,
                            overlay: false,
                            className: 'correction-dialog',
                            template: 'app/views/directives/optometrist/efron.html',
                            disableAnimation: true,
                            data: {
                                model: container
                            },
                            controller: [
                                '$scope', '$http',
                                function ($innerScope, $http) {
                                    $innerScope.question = false;

                                    $innerScope.clickQuestion = function() {
                                        $innerScope.question = !$innerScope.question;
                                    };

                                    $http.get('api/settings').then(function(res) {
                                        var _new = [];

                                        angular.forEach(res.data.efron, function(efron) {
                                            if(efron.checked) {
                                                var find = false;

                                                angular.forEach($innerScope.ngDialogData.model.efron, function (dialog_efron) {
                                                    if(dialog_efron.id === efron.id) {
                                                        _new.push(dialog_efron);

                                                        find = true;
                                                    }
                                                });

                                                if(!find) {
                                                    _new.push({
                                                        id: efron.id,
                                                        name: efron.name,
                                                        forbidden: efron.forbidden,
                                                        pivot: {
                                                            r: -1,
                                                            l: -1,
                                                            r_photo: '',
                                                            l_photo: ''
                                                        }
                                                    });
                                                }
                                            }
                                        });

                                        $innerScope.ngDialogData.model.efron = _new;

                                        _init();
                                    }, function() {
                                        $scope.$emit('error', 'settings.optometrist.notify.error.LOAD');
                                    });

                                    var _init = function() {
                                        angular.forEach($innerScope.ngDialogData.model.efron, function (efron) {
                                            efron.pivot.r = -1;
                                            efron.pivot.l = -1;
                                            efron.pivot.r_photo = '';
                                            efron.pivot.l_photo = '';
                                        });

                                        $innerScope.sides = {
                                            r: false,
                                            l: false
                                        };
                                        $innerScope.index = 0;

                                        $innerScope.sides[$innerScope.ngDialogData.model.side] = true;

                                        var switchSide = function () {
                                            if ($innerScope.ngDialogData.model.side === 'r') {
                                                $innerScope.ngDialogData.model.side = 'l';
                                            } else {
                                                $innerScope.ngDialogData.model.side = 'r';
                                            }
                                        };

                                        $innerScope.goPreviousIndex = function () {
                                            --$innerScope.index;

                                            if ($innerScope.index < 0 && $innerScope.sides.r && $innerScope.sides.l) {
                                                $innerScope.sides[$innerScope.ngDialogData.model.side] = false;
                                                $innerScope.index = $innerScope.ngDialogData.model.efron.length - 1;

                                                switchSide();
                                            }

                                            $innerScope.ngDialogData.model.efron[$innerScope.index].pivot[$innerScope.ngDialogData.model.side] = -1;
                                            $innerScope.question = false;
                                        };

                                        $innerScope.goNextIndex = function () {
                                            ++$innerScope.index;

                                            if ($innerScope.index + 1 > $innerScope.ngDialogData.model.efron.length &&
                                                !($innerScope.sides.r && $innerScope.sides.l)) {
                                                switchSide();

                                                $innerScope.sides[$innerScope.ngDialogData.model.side] = true;
                                                $innerScope.index = 0;
                                            }

                                            $innerScope.ngDialogData.model.efron[$innerScope.index].pivot[$innerScope.ngDialogData.model.side] = -1;
                                            $innerScope.question = false;
                                        };

                                        $innerScope.closeDialog = function () {
                                            dialogs.confirm('optometrist.video.confirm.cancel.MESSAGE', {
                                                className: 'ngdialog-theme-plain',
                                                data: {
                                                    confirm: 'optometrist.video.confirm.cancel.YES',
                                                    confirmIcon: 'fa fa-fw fa-check',
                                                    cancel: 'optometrist.video.confirm.cancel.NO'
                                                }
                                            }, 'true').then(function () {
                                                $innerScope.closeThisDialog(0);
                                            });
                                        };
                                    }
                                }
                            ]
                        };

                        if ($scope.ngDisabled !== 'undefined' && !$scope.ngDisabled) {
                            var dialog = ngDialog.open(options);

                            dialog.closePromise.then(function (data) {
                                if (data.value) {
                                    $scope.model[$scope.efron] = container.efron;
                                }
                            });
                        }
                    };
                }
            ]
        }
    }
]);
