angular.module('eOpti.directives').directive('matchHeight', [
    '$window',
    '$timeout',
    function ($window, $timeout) {
        return {
            restrict: 'A',
            scope: false,
            link: function (scope, element, attributes) {
                let selector = attributes.matchHeight

                let applyHeight = function (parent, childrenClass) {
                    if (!parent) return;
                    const children = parent.getElementsByClassName(childrenClass)
                    if (!children) return;

                    Array.from(children)
                        .forEach(x => x.style.height = 'initial')

                    const itemHeights = Array.from(children)
                        .map(x => x.getBoundingClientRect().height)

                    const maxHeight = itemHeights.reduce((prev, curr) => {
                        return curr > prev ? curr : prev
                    }, 0)

                    Array.from(children)
                        .forEach(x => x.style.height = `${maxHeight}px`)
                }
                let applyHeightToThis = () => {
                    applyHeight(element[0], selector)
                }

                scope.$on('match-height', applyHeightToThis)
                $window.addEventListener('resize', applyHeightToThis)
            }
        }
    }
]);
