angular.module('eOpti.directives.inputs.select').directive('range', [
    '$compile',
    function ($compile) {
        return {
            restrict: 'A',
            scope: {
                value: '=?ngModel',
                range: '='
            },
            compile: function (elem) {
                elem.attr('ng-options', 'r as r for r in tab');
                elem.removeAttr('range');

                return function(scope) {
                    scope.tab = [];

                    for(var i = scope.range[0]; i <= scope.range[1]; ++i) {
                        scope.tab.push((i).toString());
                    }

                    $compile(elem)(scope);
                }
            }
        };
    }
]);
