angular.module('eOpti.directives.inputs.select').directive('enum', ['$enum', function ($enum) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: '=',
            data: '@'
        },
        templateUrl: 'app/views/directives/inputs/select/enum.html',
        link: function(scope) {
            scope.enum = []

            $enum.get(scope.data).then(res => {
                scope.enum = res
            })
        }
    }
}])