angular.module('eOpti.directives.inputs.numbers').directive('numberprescription', [
    '$document',
    'ngDialog',
    function ($document, ngDialog) {
        return {
            restrict: 'E',
            template: '<span ng-click="showEditDialog()" ng-transclude></span>',
            replace: true,
            transclude: true,
            scope: {
                field: '@',
                model: '=',
                heading: '@',
                ngDisabled: '=?',
                block: '=?',
                none: '=?',
                noneName: '@?'
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.showEditDialog = function () {
                        $scope.options = {
                            closeByDocument: false,
                            showClose: false,
                            className: 'ngdialog-theme-plain',
                            template: 'app/views/directives/inputs/numbers/number-prescription.html',
                            disableAnimation: true,
                            data: {
                                model: {
                                    value: $scope.model[$scope.field].toString()
                                        .replace(',', '.')
                                        .replace('+', '')
                                },
                                isSign: (['rePrism', 'lePrism', 'dist_r_prd', 'dist_l_prd', 'near_r_prd', 'near_l_prd', 'r_prd', 'l_prd']).indexOf($scope.field) > -1 ? false : true
                            },
                            controller: [
                                '$scope',
                                '$timeout',
                                '$document',
                                function ($innerScope, $timeout, $document) {
                                    $innerScope.firstAdd = true;
                                    $innerScope.activePlus = false;
                                    $innerScope.activeMinus = false;

                                    $innerScope.active2 = {
                                        '00': false,
                                        '25': false,
                                        '50': false,
                                        '75': false
                                    };

                                    if(typeof $scope.noneName === 'undefined') {
                                        $scope.noneName = 'brak';
                                    }

                                    if(typeof $scope.none !== 'undefined' && parseInt($scope.none) === parseInt($innerScope.ngDialogData.model.value)) {
                                        $innerScope.ngDialogData.model.value = $scope.noneName;
                                    }

                                    var setAsDirty = function() {
                                        if ($innerScope.ngDialogData.model.value === $scope.noneName) {
                                            $innerScope.ngDialogData.model.value = '0';
                                        }
                                    };

                                    $innerScope.parseFloat = function(float) {
                                        return float;
                                    };

                                    if($innerScope.ngDialogData.model.value.indexOf('-') !== -1) {
                                        $innerScope.activeMinus = true;
                                    } else if(parseFloat($innerScope.ngDialogData.model.value) > 0) {
                                        $innerScope.activePlus = true;
                                    }

                                    $scope.$watch('block', function (nVal) {
                                        $innerScope.block = nVal;
                                    });

                                    $innerScope.$watch('ngDialogData.model.value', function(newValue, oldValue) {
                                        if(parseFloat(newValue) != parseFloat(oldValue)) {
                                            $innerScope.firstAdd = false;
                                        }

                                        if (newValue.indexOf('.') !== -1) {
                                            var startData = newValue.split('.');

                                            $innerScope.numPart1 = startData[0];
                                            if(startData[1].length == 1) {
                                                startData[1] += '0';
                                            }

                                            $innerScope.numPart2 = startData[1];

                                            $innerScope.active2[startData[1]] = true;

                                            $innerScope.ngDialogData.model.value = startData.join('.');
                                        } else if(newValue !== $scope.noneName) {
                                            $innerScope.ngDialogData.model.value += '.00';
                                        }
                                    }, true);

                                    $innerScope.heading = $scope.heading;

                                    $innerScope.classHeading = {
                                        'panel-info': true
                                    };

                                    if($scope.field.substr(0, 2) == 're') {
                                        $innerScope.classHeading = {
                                            'panel-green': true
                                        }
                                    } else if($scope.field.substr(0, 2) == 'le') {
                                        $innerScope.classHeading = {
                                            'panel-pink': true
                                        }
                                    }

                                    $innerScope.getActive2 = function() {
                                        var result = false;
                                        angular.forEach($innerScope.active2, function(value, key) {
                                            if(value) {
                                                result = key;
                                            }
                                        });

                                        return result;
                                    };

                                    $innerScope.active2press = function (num) {
                                        angular.forEach($innerScope.active2, function (value, key) {
                                            $innerScope.active2[key] = false;
                                        });

                                        $innerScope.active2[num] = true;
                                    };

                                    $innerScope.add = function (num) {
                                        setAsDirty();

                                        if (typeof $innerScope.ngDialogData.model.value == 'undefined' ||
                                            $innerScope.ngDialogData.model.value[0] == '0' || $innerScope.firstAdd
                                        ) {
                                            if(!$innerScope.getActive2()) {
                                                $innerScope.ngDialogData.model.value = num + '.00';
                                                $innerScope.active2press('00');
                                                $innerScope.firstAdd = false;
                                            } else {
                                                $innerScope.ngDialogData.model.value = num + '.' + $innerScope.getActive2();
                                            }
                                        } else {
                                            if ($innerScope.ngDialogData.model.value.indexOf('.') !== -1) {
                                                var nums = $innerScope.ngDialogData.model.value.split('.');
                                                    nums[0] = nums[0].replace('-', '');

                                                if (nums[0].length == 2) {
                                                    nums[0] = nums[0][0] + num;
                                                } else {
                                                    if(nums[0] == 0) {
                                                        nums[0] = num;
                                                    } else {
                                                        nums[0] += num;
                                                    }
                                                }

                                                if($innerScope.activeMinus) {
                                                    nums[0] = '-' + nums[0];
                                                }

                                                $innerScope.ngDialogData.model.value = nums.join('.');
                                            } else {
                                                if ($innerScope.ngDialogData.model.value.length == 2) {
                                                    $innerScope.ngDialogData.model.value =
                                                        $innerScope.ngDialogData.model.value[0][0] + num;
                                                } else {
                                                    $innerScope.ngDialogData.model.value += num;
                                                }
                                            }
                                        }

                                        if($innerScope.activeMinus && parseFloat($innerScope.ngDialogData.model.value) > 0) {
                                            $innerScope.ngDialogData.model.value = ($innerScope.ngDialogData.model.value * -1).toString();
                                        }
                                    };

                                    $innerScope.add2 = function (num) {
                                        setAsDirty();

                                        if (!(typeof $innerScope.ngDialogData.model.value == 'undefined' ||
                                            $innerScope.ngDialogData.model.value[0] == '0')
                                        ) {
                                            if ($innerScope.ngDialogData.model.value.indexOf('.') !== -1) {
                                                var nums = $innerScope.ngDialogData.model.value.split('.');

                                                nums[1] = num;

                                                $innerScope.ngDialogData.model.value = nums.join('.');
                                            } else {
                                                $innerScope.ngDialogData.model.value += '.' + num;
                                            }

                                            $innerScope.active2press(num);
                                        } else {
                                            $innerScope.ngDialogData.model.value = '0.' + num;
                                            $innerScope.active2press(num);
                                        }

                                        if($innerScope.activeMinus && parseFloat($innerScope.ngDialogData.model.value) > 0) {
                                            $innerScope.ngDialogData.model.value = ($innerScope.ngDialogData.model.value * -1).toString();
                                        }
                                    };

                                    $innerScope.addMinus = function () {
                                        setAsDirty();

                                        $innerScope.activeMinus = !$innerScope.activeMinus;

                                        if($innerScope.activeMinus) {
                                            $innerScope.activePlus = false;
                                        }

                                        $innerScope.ngDialogData.model.value = ($innerScope.ngDialogData.model.value * -1).toString();

                                        $innerScope.firstAdd = false;
                                    };

                                    $innerScope.addPlus = function () {
                                        setAsDirty();

                                        $innerScope.activePlus = !$innerScope.activePlus;

                                        if($innerScope.activePlus) {
                                            $innerScope.activeMinus = false;

                                            if(parseFloat($innerScope.ngDialogData.model.value) < 0) {
                                                $innerScope.ngDialogData.model.value = ($innerScope.ngDialogData.model.value * -1).toString();
                                            }
                                        }

                                        $innerScope.firstAdd = false;
                                    };

                                    $innerScope.clear = function () {
                                        setAsDirty();

                                        $innerScope.ngDialogData.model.value = '0.00';
                                        $innerScope.active2press('00');
                                        $innerScope.activeMinus = false;
                                        $innerScope.activePlus = false;
                                        $innerScope.firstAdd = false;
                                        $innerScope.pressDot = false;
                                    };

                                    $innerScope.backspace = function () {
                                        setAsDirty();

                                        if ($innerScope.firstAdd) {
                                            $innerScope.clear();
                                            return;
                                        }

                                        var str = $innerScope.ngDialogData.model.value;
                                            str = str.replace('-', '');

                                        if (typeof str == 'undefined' ||
                                            str == '' ||
                                            str.length == 1) {
                                            str = '0.00';
                                            $innerScope.active2press('00');
                                            $innerScope.activeMinus = false;
                                            $innerScope.activePlus = false;
                                        }

                                        if (str.indexOf('.') !== -1) {
                                            var nums = str.split('.');

                                            if (nums[0].length == 2) {
                                                nums[0] = nums[0][0];
                                            } else {
                                                nums[0] = '0';
                                                nums[1] = '00';
                                                $innerScope.active2press('00');
                                                $innerScope.activeMinus = false;
                                                $innerScope.activePlus = false;
                                            }

                                            str = nums.join('.');
                                        } else {
                                            if (str.length == 2) {
                                                str =
                                                    str[0];
                                            } else {
                                                str = '0.00';
                                                $innerScope.active2press('00');
                                                $innerScope.activeMinus = false;
                                                $innerScope.activePlus = false;
                                            }
                                        }

                                        if($innerScope.activeMinus) {
                                            str = '-' + str;
                                        }

                                        $innerScope.ngDialogData.model.value = str;
                                    };

                                    $innerScope.pressDot = false;

                                    var keydown = function (e) {
                                        var key = e.which;

                                        if (key >= 96 && key <= 106) {
                                            key -= 48;
                                        }

                                        switch (key) {
                                            case 48: // 0
                                                if($innerScope.pressDot) {
                                                    $innerScope.$apply($innerScope.add2('00'));
                                                } else {
                                                    $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                }
                                                break;
                                            case 50: // 2
                                                if($innerScope.pressDot) {
                                                    $innerScope.$apply($innerScope.add2('25'));
                                                } else {
                                                    $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                }
                                                break;
                                            case 53: // 5
                                                if($innerScope.pressDot) {
                                                    $innerScope.$apply($innerScope.add2('50'));
                                                } else {
                                                    $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                }
                                                break;
                                            case 55: // 7
                                                if($innerScope.pressDot) {
                                                    $innerScope.$apply($innerScope.add2('75'));
                                                } else {
                                                    $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                }
                                                break;
                                            case 49: // 1
                                            case 51: // 3
                                            case 52: // 4
                                            case 54: // 6
                                            case 56: // 8
                                            case 57: // 9
                                                if(!$innerScope.pressDot) {
                                                    $innerScope.$apply($innerScope.add((key - 48).toString()));
                                                }
                                                break;
                                            case 8:   // backspace
                                                if($innerScope.pressDot) {
                                                    $innerScope.pressDot = false;
                                                    $innerScope.$apply($innerScope.add2('00'));
                                                } else {
                                                    $innerScope.$apply($innerScope.backspace());
                                                }

                                                e.preventDefault();
                                                break;
                                            case 46: // delete
                                                $innerScope.$apply($innerScope.clear());
                                                e.preventDefault();
                                                break;
                                            case 13:  // enter
                                                if (!$innerScope.editor.$invalid &&
                                                    !($innerScope.ngDialogData.isSign && !$innerScope.activePlus && !$innerScope.activeMinus)) {
                                                    $innerScope.$apply($innerScope.closeThisDialog(1));
                                                }
                                                e.preventDefault();
                                                break;
                                            case 188:  // comma
                                            case 190:  // dot
                                            case 108:  // dot (numpad)
                                            case 110:  // dot (windows)
                                                $innerScope.pressDot = true;
                                                break;
                                            case 109: // minus
                                            case 189:
                                                if($innerScope.ngDialogData.isSign) {
                                                    $innerScope.addMinus();
                                                }
                                                break;
                                            case 107: // plus
                                            case 187:
                                                if($innerScope.ngDialogData.isSign) {
                                                    $innerScope.addPlus();
                                                }
                                        }
                                    };

                                    $document.on('keydown', keydown);
                                    $innerScope.$on('$destroy', function () {
                                        $document.off('keydown', keydown);
                                    });
                                }
                            ]
                        };

                        if(!$scope.ngDisabled) {
                            var dialog = ngDialog.open($scope.options);
                            dialog.closePromise.then(function (data) {
                                if (data.value == 1) {
                                    if(typeof $scope.none !== 'undefined' && $scope.options.data.model.value === $scope.noneName) {
                                        $scope.model[$scope.field] = parseInt($scope.none);
                                    } else {
                                        $scope.model[$scope.field] =
                                            Math.round(parseFloat($scope.options.data.model.value) * 100) / 100 || 0;
                                    }
                                }
                            });
                        }
                    }
                }
            ]
        }
    }
]);
