angular.module('eOpti.directives.dialogs').controller('budget.ambitious', [
    '$scope', '$state', '$http', 'uac',
    function ($scope, $state, $http, uac) {
        $scope.department_id = $scope.ngDialogData.department_id
        $scope.title = $scope.ngDialogData.title
        $scope.time = {
            year: 2000 + parseInt($scope.title.split('Q')[1]),
            quarter: parseInt($scope.title.split('Q')[0])
        }

        $scope.users = []

        let findIndexToday = () => {
            let _return = false

            if($scope.ngDialogData.users.length) {
                $scope.ngDialogData.users[0].data.forEach((week, key) => {
                    if(week.isToday) {
                        _return = key
                    }
                })
            }

            return _return
        }

        let todayIndex = findIndexToday()

        $scope.ngDialogData.users.forEach(function(user) {
            // to samo co w BudgetExcelPlanningExporter.php:sheetAmbitious()

            let last = user.data[Object.keys(user.data).length - 1],
                label = user.data[todayIndex !== false ? todayIndex : Object.keys(user.data).length - 1]

            if(!label) {
                label = {
                    turn_over_current: {
                        tasks: 0,
                        sales: 0
                    }
                }
            }

            $scope.users.push({
                user_id: user.user_id,
                user_name: user.user_name,
                color: user.user_color,
                department: user.user_department,
                exchange: user.exchange,
                active: user.user_active,
                sun: user.user_sun,
                hours: user.sum.hours,
                hours_corrected: user.sum.hours_corrected,
                turnover: {
                    label: parseInt(label.turn_over_current.tasks + label.turn_over_current.sales),
                    previous: {
                        all: parseInt(last.turn_over.tasks + last.turn_over.sales),
                        optics: parseInt(last.turn_over.tasks)
                    },
                    current: {
                        all: parseInt(last.turn_over_current.tasks + last.turn_over_current.sales),
                        optics: parseInt(last.turn_over_current.tasks)
                    },
                    ambitious: {
                        all: last.turn_over_ambitious.all,
                        optics: last.turn_over_ambitious.optics
                    }
                }
            })
        })

        let getSumCentral = () => {
            let _return = {
                all: 0,
                optics: 0
            }

            if($scope.ngDialogData.users.length) {
                $scope.ngDialogData.users[0].data.forEach(function(data) {
                    _return.all += data.central.all
                    _return.optics += data.central.optics
                })
            }

            return _return
        }

        let department = getSumCentral()
        $scope.calculations = {
            central: {
                all: department.all,
                optics: department.optics
            },
            users: {all: 0, optics: 0},
            difference: { all: 0, optics: 0}
        }

        $scope.calculate = function () {
            let all = 0
            let optics = 0

            $scope.users.forEach(function (user) {
                all += parseInt(parseInt(user.turnover.ambitious.all) * user.hours)
                optics += parseInt(parseInt(user.turnover.ambitious.optics) * user.hours)
            })

            $scope.calculations.users.all = all
            $scope.calculations.users.optics = optics
            $scope.calculations.difference.all = all - $scope.calculations.central.all
            $scope.calculations.difference.optics = optics - $scope.calculations.central.optics
        }

        $scope.calculate()

        $scope.isAdmin = true //uac.user.activeRole.admin odblokowanie zapisu

        $scope.saveAmbitious = () => {
            $http.post('api/budget/turn-over/add-edit', {
                department_id: $scope.department_id,
                time: $scope.time,
                users: $scope.users
            }).then(() => {
                $scope.confirm()

                $scope.$emit('error', 'schedule.budget.ambitious.notify.ADD')
            }, error => {
                if(error.status === 403) {
                    $scope.$emit('notify', {
                        status: 'danger',
                        translate: false,
                        message: error.data.message
                    })
                } else {
                    $scope.$emit('error', 'schedule.budget.ambitious.notify.error.ADD')
                }
            })
        }

        $scope.goToUser = user_id => {
            $state.go('app.user.edit', {
                id: user_id
            })

            $scope.confirm()
        }
    }
])
