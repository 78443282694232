angular.module('eOpti.directives.dialogs').directive('product', [
    '$translate',
    '$http',
    'dialogs',
    function ($translate, $http, dialogs) {
        return {
            restrict: 'A',
            scope: {
                product: '=',
                productDepartmentId: '=?'
            },
            link: function (scope, elem) {
                $translate('default.title.PRODUCT').then(function(text) {
                    elem.attr('title', text);
                });

                elem.bind('click', function () {
                    if (!angular.isObject(scope.product) && angular.isNumber(scope.product) && scope.product > 0) {
                        $http.get('api/warehouse/products/get/' + scope.product).then(function(res) {
                            dialogs.confirm(null, {
                                template: 'app/views/_dialogs/product.html',
                                className: 'ngdialog-theme-plain',
                                data: {
                                    product: res.data,
                                    department_id: scope.productDepartmentId
                                },
                                controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                                    $rootScope.$on('dialog-close', function() {
                                        $scope.closeThisDialog();
                                    });
                                }]
                            });
                        }, function(error) {
                            console.log(error);
                        });

                    } else if(scope.product.id) {
                        dialogs.confirm(null, {
                            template: 'app/views/_dialogs/product.html',
                            className: 'ngdialog-theme-plain',
                            data: {
                                product: scope.product,
                                department_id: scope.productDepartmentId
                            },
                            controller: ['$rootScope', '$scope', function($rootScope, $scope) {
                                $rootScope.$on('dialog-close', function() {
                                    $scope.closeThisDialog();
                                });
                            }]
                        });
                    }
                });
            }
        };
    }
]);
