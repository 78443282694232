angular.module('eOpti.directives').directive('viewBox', [
    function () {
        return {
            link: function (scope, element, attributes) {
                attributes.$observe('viewBox', function (value) {
                    element[0].setAttribute('viewBox', value);
                })
            }
        }
    }
]);
