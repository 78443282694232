angular.module('eOpti.directives').directive('ngSetFocus', ['$timeout',
    function ($timeout) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {

                var delay = 300;

                // set focus on broadcast
                scope.$on(attrs.ngSetFocus, function(e) {
                    $timeout(function(){
                        if(typeof attrs.selector === 'undefined') {
                            element[0].focus();
                        } else {
                            element[0].querySelector(attrs.selector).click();
                        }
                    }, delay);

                });

                // apply default focus after other events have complete
                $timeout(function(){
                    if(attrs.hasOwnProperty('setFocusDefault')){
                        if(typeof attrs.selector === 'undefined') {
                            element[0].focus();
                        } else {
                            element[0].querySelector(attrs.selector).click();
                        }
                    }
                }, delay);

                // fix for default focus on iOS. Does not show keyboard
                element.on('touchstart', function(event) {
                    if(typeof attrs.selector === 'undefined') {
                        element[0].blur();
                    } else {
                        element[0].querySelector(attrs.selector).click();
                    }
                });

            }
        }
    }
]);