angular.module('eOpti.directives.inputs').directive('date', function() {
    return {
        restrict: 'E',
        require: 'ngModel',
        templateUrl: 'app/views/directives/inputs/date.html',
        replace: true,
        scope: {
            ngModel: '=',
            name: '@?',
            ngDisabled: '=?',
            ngModelOptionsUpdate: '@?',
            ngChange: '&?',
            ngRequired: '=?'
        },
        link: function (scope)
        {
            scope.updateModel = function(item)
            {
                scope.ngChange();
            }
        },
        controller: ['$scope', function($scope) {
            $scope.filter = {
                isCalendarOpen: false
            };

            $scope.openCalendar = function (filter, event) {
                event.preventDefault();
                event.stopPropagation();
                filter.isCalendarOpen = true;
            };
        }]
    };
});
