angular.module('eOpti.directives').directive('smokescreen', [
    function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/views/directives/smokescreen.html',
            transclude: true,
            scope: {
                pending: '=',
                header: '@'
            },
            controller: ['$scope', function($scope) {
                $scope.header = typeof $scope.header != 'undefined' ?  $scope.header : 'default.PENDING';
            }]
        }
    }
]);